import mapVerify from '@/utils/formValidate'

export const getMoreQueryFormData = (that) => {
  const moreQueryData = [
    // 容器类型
    {
      name: 'containerTypeId',
      span: 12,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.containerType'),
      data: that.containerTypeList
    },
    // 容器编码
    {
      name: 'containerArchivesCode',
      span: 12,
      component: 'elInput',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.containerArchivesCode')
    },
    // 归属区域
    {
      name: 'areaId',
      span: 12,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.belongingArea'),
      data: that.areaList,
      mchange: that.handleAreaChange
    },
    // 归属产线
    {
      name: 'productionLineId',
      span: 12,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.belongingProductionLine'),
      data: that.productionLineList
    },
    // 所在库区
    {
      name: 'warehouseAreaId',
      span: 12,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.belongingWarehouseArea'),
      data: that.warehouseAreaList,
      mchange: that.handleWarehouseArea
    },
    // 外部编码
    {
      name: 'hostCode',
      span: 12,
      component: 'elInput',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.hostCode')
    },
    // 库位
    {
      name: 'factoryPositionId',
      span: 12,
      component: 'RemoteSelect',
      labelText: 'factoryPositionCode',
      label: that.$t('lang.gles.base.belongingFactoryPosition'),
      clearable: true
    },
    // 启用状态
    {
      name: 'usableFlag',
      span: 12,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.usableFlag'),
      data: that.statusFlagList
    }
  ]
  return moreQueryData
}
// 查询列表列表
export const getSearchTableItem = (that, options) => {
  const searchTableItem = [
    // 容器编码
    {
      prop: 'containerArchivesCode',
      label: that.$t('lang.gles.base.containerArchivesCode'),
      isShow: true,
      sortable: true,
      minWidth: 140
    },
    // 类型编码
    {
      prop: 'containerTypeCode',
      label: that.$t('lang.gles.base.containerTypeCode'),
      isShow: true,
      minWidth: 140
    },
    {
      prop: 'containerTypeName',
      label: that.$t('lang.gles.base.containerTypeName'),
      isShow: true,
      minWidth: 140
    },
    // 容器形态
    {
      prop: 'containerTypePattern',
      label: that.$t('lang.gles.base.containerTypePattern'),
      isShow: true,
      minWidth: 140,
      formatter(row, column) {
        return that.getDictLabel(that.containerTypePatternList, row[column])
      }
    },
    // 外部编码
    {
      prop: 'hostCode',
      label: that.$t('lang.gles.base.hostCode'),
      isShow: true,
      showOverflowTooltip: true
    },
    // 所在库区
    {
      prop: 'warehouseAreaId',
      label: that.$t('lang.gles.base.belongingWarehouseArea'),
      isShow: true,
      formatter(row, column) {
        return that.getDictLabel(that.warehouseAreaList, row[column])
      }
    },
    // 存放库位
    {
      prop: 'factoryPositionCode',
      label: that.$t('lang.gles.base.storageFactoryPosition'),
      isShow: true,
      minWidth: 200
    },
    // 状态
    {
      prop: 'containerStatus',
      label: that.$t('lang.gles.base.status'),
      isShow: true,
      formatter(row, column) {
        return that.getDictLabel(that.containerStatusList, row[column])
      }
    },
    // 启用状态
    {
      prop: 'usableFlag',
      label: `${that.$t('lang.gles.base.usableFlag')}`,
      isShow: true,
      slotName: 'usableFlag',
      showOverflowTooltip: false,
      minWidth: 78
    },
    // 创建人
    {
      prop: 'createUser',
      label: that.$t('lang.gles.common.createUser'),
      isShow: true
    },
    // 创建时间
    {
      prop: 'createTime',
      label: that.$t('lang.gles.common.createTime'),
      isShow: true,
      minWidth: 180
    },
    // 编辑人
    {
      prop: 'updateUser',
      label: that.$t('lang.gles.common.updateUser'),
      isShow: true
    },
    // 编辑时间
    {
      prop: 'updateTime',
      label: that.$t('lang.gles.common.updateTime'),
      isShow: true,
      minWidth: 180
    }
  ]
  searchTableItem.forEach((item) => (item.showOverflowTooltip = true))
  return searchTableItem
}

// 编辑弹框 基础信息
export const getEditBaseFormData = (that, row) => {
  const editContainerFile = [
    // 容器类型
    {
      name: 'containerTypeId',
      value: row.containerTypeId || '',
      span: 8,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.containerType'),
      rules: mapVerify(['required']),
      clearable: true,
      data: that.containerTypeList,
      filterable: true,
      isShow: true,
      mchange: that.handleContainerType,
      disabled: that.mode !== 'add'
    },
    // 归属区域
    {
      name: 'areaId',
      value: row.areaId || '',
      span: 8,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.belongingArea'),
      data: that.areaList,
      isShow: true,
      mchange: that.handleAreaChange,
      multiple: true,
      collapseTags: true
    },
    // 归属产线
    {
      name: 'productionLineId',
      value: row.productionLineId || '',
      span: 8,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.belongingProductionLine'),
      clearable: true,
      data: that.productionLineList,
      filterable: true,
      isShow: true,
      multiple: true,
      collapseTags: true
    },
    // 所在库区
    {
      name: 'warehouseAreaId',
      value: row.warehouseAreaId || '',
      span: 8,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.belongingWarehouseArea'),
      rules: that.containerTypePatternType === 'fixedContainer' ? mapVerify(['required']) : [],
      clearable: true,
      data: that.warehouseAreaList,
      filterable: true,
      isShow: true,
      mchange: that.handleWarehouseArea,
      disabled: that.mode !== 'add' && that.containerTypePatternType === 'moveContainer'
    },
    // 容器编码
    {
      name: 'containerArchivesCode',
      value: row.containerArchivesCode || '',
      span: 8,
      component: 'elInput',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.containerArchivesCode'),
      rules: mapVerify(['required']),
      clearable: true,
      isShow: that.containerTypePatternType === 'fixedContainer',
      disabled: that.mode !== 'add'
    },
    // 外部编码
    {
      name: 'hostCode',
      value: row.hostCode || '',
      span: 8,
      component: 'elInput',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.hostCode'),
      clearable: true,
      isShow: that.containerTypePatternType === 'fixedContainer'
    },
    // 编码字母前缀
    {
      name: 'codedLetterPrefix',
      value: row.codedLetterPrefix || '',
      span: 8,
      component: 'elInput',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.codedLetterPrefix'),
      isShow: that.isShowCodeRule,
      rules: mapVerify(['wordsLen0~8'])
    },
    // 新增数量
    {
      name: 'addNum',
      value: row.addNum || '',
      span: 8,
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.addNum'),
      disabled: that.containerTypePatternType === 'fixedContainer',
      isShow: that.mode === 'add' && that.isShowCodeRule,
      rules: mapVerify(['maxNum100'])
    },
    // 启用状态
    {
      name: 'usableFlag',
      value: row.usableFlag,
      span: 8,
      label: that.$t('lang.gles.base.usableFlag'),
      class: 'switch',
      isShow: that.containerTypePatternType === 'fixedContainer',
      slotName: 'usableFlagSlot'
    }
  ]
  editContainerFile.forEach((item) => {
    if (!item.mchange) {
      item.mchange = that.onFormChange
    }
  })
  return editContainerFile.filter((item) => item.isShow)
}

export const getArchivesTableItem = (that) => {
  const searchTableItem = [
    {
      prop: 'containerArchivesCode',
      component: 'elInput',
      label: that.$t('lang.gles.base.containerArchivesCode'),
      isShow: true,
      clearable: true,
      disabled: that.mode !== 'add',
      rules: mapVerify(['required', 'inputCode'])
    },
    // 外部编码
    {
      prop: 'hostCode',
      label: that.$t('lang.gles.base.hostCode'),
      component: 'elInput',
      isShow: true,
      clearable: true,
      rules: mapVerify(['inputCode'])
    },
    // 存放库位
    {
      prop: 'factoryPositionId',
      label: that.$t('lang.gles.base.belongingFactoryPosition'),
      component: 'RemoteSelect',
      queryType: 'usableFactoryPositionList',
      showLabel: 'factoryPositionName',
      isShow: true,
      clearable: true,
      disabled: that.mode !== 'add' || that.containerTypePatternType === 'turnoverContainer'
      // data: that.usableFactoryPositionList
    },
    // 启用状态
    {
      prop: 'usableFlag',
      label: that.$t('lang.gles.base.usableFlag'),
      // component: 'elSwitch',
      activeText: that.$t('lang.gles.common.enable'),
      inactiveText: that.$t('lang.gles.common.disable'),
      activeValue: 0,
      inactiveValue: 1,
      isShow: true,
      class: 'switch',
      slotName: 'usableFlag'
    }
  ]

  return searchTableItem
}

// 获取容器位置
export const getArchivesDetailTableItem = (that) => {
  const searchTableItem = [
    {
      prop: 'containerArchivesColumn',
      component: 'elInputNumber',
      label: that.$t('lang.gles.base.column'),
      isShow: true,
      disabled: true,
      controls: false
    },
    // 存放库位
    {
      prop: 'factoryPositionId',
      label: that.$t('lang.gles.base.belongingFactoryPosition'),
      component: 'RemoteSelect',
      queryType: 'usableFactoryPositionList',
      showLabel: 'factoryPositionName',
      isShow: true,
      clearable: true,
      // data: that.usableFactoryPositionList || [],
      filterable: true,
      rules: mapVerify(['required'])
    }
  ]

  return searchTableItem
}

<template>
  <div class="basic-data-add">
    <header-path :paths="headerPaths" />
    <el-card class="add-card">
      <m-form
        ref="myForm"
        :form-data="baseFormData"
        label-position="right"
        :label-width="120"
        :extend-config="extendFormConfig"
      >
        <template v-if="mode === 'add'" #appendComponent="{ item }">
          <el-input
            :value="item.value"
            v-bind="item"
            class="gles"
            @input="handleInputBatchNum"
          >
            <el-button
              slot="append"
              v-loading="vloading"
              type="primary"
              :disabled="
                vloading || containerTypePatternType === 'fixedContainer'
              "
              @click="() => handleBatchAdd(formModel)"
            >
              {{ $t('lang.gles.common.batchAdd') }}
            </el-button>
          </el-input>
        </template>
        <template #usableFlagSlot="{ item }">
          <el-switch
            :value="item.value"
            class="switch"
            :active-text="$t('lang.gles.common.enable')"
            :inactive-text="$t('lang.gles.common.disable')"
            :active-value="0"
            :inactive-value="1"
            @click.native="updateUsableFlag(item.value, formModel)"
          />
        </template>
      </m-form>
      <div
        v-if="isShowContainerPosition"
        class="fixed-shelf"
        style="margin-left: 50px"
      >
        <h3 class="title" style="margin-bottom: 10px">
          {{ $t('lang.gles.base.containerPosition') }}
        </h3>
        <table-form
          ref="positionTableForm"
          :table-item="archivesDetailTableItem"
          :table-data.sync="containerArchivesDetailList"
          :extend-config="tableExtendConfig"
        />
      </div>
      <div
        v-if="!isShowContainerPosition && formModel.containerTypeId"
        class="fixed-shelf"
        style="margin-left: 50px"
      >
        <table-form
          ref="containerTableForm"
          :table-item="archivesTableItem"
          :table-data.sync="containerArchivesList"
          :extend-config="tableExtendConfig"
        >
          <template #usableFlag="{ row, $index }">
            <el-switch
              :value="row.usableFlag"
              class="switch"
              :active-text="$t('lang.gles.common.enable')"
              :inactive-text="$t('lang.gles.common.disable')"
              :active-value="0"
              :inactive-value="1"
              @click.native="
                updateUsableFlag(row.usableFlag, containerArchivesList[$index])
              "
            />
          </template>
        </table-form>
      </div>
      <div class="btns">
        <el-button @click="onCancel">
          {{ $t('lang.gles.common.cancel') }}
        </el-button>
        <el-button v-debounce="onSubmit" type="primary">
          {{ $t('lang.gles.common.save') }}
        </el-button>
      </div>
    </el-card>
  </div>
</template>
<script>
import _ from 'lodash'
import HeaderPath from '@/components/headerPath/index.vue'
import TableForm from '@/components/tableForm/index.vue'
import MForm from '@/libs_sz/components/MForm/MForm'
import addMixins from '@/mixins/addMixins'
import commonMixins from '@/mixins/commonMixins'
import {
  getEditBaseFormData,
  getArchivesTableItem,
  getArchivesDetailTableItem
} from '../data'
import { mapState } from 'vuex'

export default {
  name: 'ContainerFileAdd',
  components: {
    HeaderPath,
    TableForm,
    MForm
  },
  mixins: [addMixins, commonMixins],
  props: {
    mode: {
      type: String,
      default: 'add'
    },
    rowDetail: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      // form表单相关的
      extendFormConfig: {
        isNeedBtn: false
      },
      tableExtendConfig: {
        sortNum: true,
        maxHeight: '400px',
        border: true,
        style: {
          // width: '70%'
        }
      },
      formModel: {
        codedLetterPrefix: 'CN',
        usableFlag: 0,
        addNum: 1,
        areaId: [],
        productionLineId: []
      },
      tableFormData: [],
      containerArchivesDetailList: [],
      containerArchivesList: [],
      containerTypeList: [],
      isSimpleModel: true
    }
  },
  computed: {
    ...mapState('base', ['containerTypePatternTypeList']),
    ...mapState('moreData', ['usableFactoryPositionList']),
    headerPaths() {
      return this.mode === 'add'
        ? [this.$t('lang.gles.container.containerArchivesAdd')]
        : [this.$t('lang.gles.container.containerArchivesEdit')]
    },
    baseFormData() {
      return getEditBaseFormData(this, {
        ...this.formModel,
        isShowCodeRule: this.isShowCodeRule,
        addNum: this.formModel?.addNum,
        codedLetterPrefix: this.formModel.codedLetterPrefix
      })
    },
    archivesTableItem() {
      return getArchivesTableItem(this)
    },
    archivesDetailTableItem() {
      return getArchivesDetailTableItem(this)
    },
    /**
     * 容器形态类型
     */
    containerTypePatternType() {
      return (
        this.containerTypeList?.find(
          (t) => t.value === this.formModel.containerTypeId
        )?.containerTypePatternType ?? null
      )
    },
    /**
     * 是否显示容器位置信息
     */
    isShowContainerPosition() {
      return this.containerTypePatternType === 'fixedContainer'
    },
    /**
     * 是否显示编码生成规则
     */
    isShowCodeRule() {
      return (
        this.mode === 'add' &&
        ['moveContainer', 'turnoverContainer'].includes(
          this.containerTypePatternType
        )
      )
    }
    /**
     * 未被容器使用的库位
     */
    /* usableFactoryPositionList() {
      let usedList =
        this.containerArchivesDetailList.map((t) => t.factoryPositionId) ?? []
      usedList = usedList.concat(
        this.containerArchivesList.map((t) => t.factoryPositionId) ?? []
      )
      const usableList = this._.cloneDeep(this.noUsefactoryPositionList)
      usableList.forEach((t) => {
        t.disabled = usedList.includes(t.value)
      })
      return usableList
    } */
  },
  watch: {
    containerArchivesList: {
      handler(list) {
        this.updateUsableFactoryPositionList(list)
      },
      deep: true
    },
    containerArchivesDetailList: {
      handler(list) {
        this.updateUsableFactoryPositionList(list)
      },
      deep: true
    }
  },
  mounted() {
    if (this.mode !== 'add') {
      const { containerArchivesList = [] } = this.rowDetail
      this.formModel = containerArchivesList?.length
        ? containerArchivesList[0]
        : _.cloneDeep(this.rowDetail)
      // 查询详情
      this.getDetail({ id: this.rowDetail.id })
    }
    this.initSelectList()
  },
  methods: {
    initSelectList() {
      this.getContainerTypeList()
      this.getAreaList()
      this.getWarehouseAreaList()
      this.$store.dispatch('moreData/addUsableFactoryPositionList', [])
      if (this.mode === 'edit') {
        this.getProductionLineList({
          areaIdList: (this.formModel?.areaId?.split(',') ?? []).map((t) =>
            parseInt(t)
          )
        })
        this.getNoUseFactoryPositionList({
          warehouseAreaId: this.formModel.warehouseAreaId,
          containerArchivesId: this.formModel.id,
          usableFlag: 0
        })
      }
    },
    updateUsableFactoryPositionList(list = []) {
      const usedList = (list || []).map((t) => t.factoryPositionId) ?? []
      const usableList = this._.cloneDeep(this.usableFactoryPositionList)
      usableList.forEach((t) => {
        t.disabled = usedList.includes(t.value)
      })
      this.$store.dispatch('moreData/addUsableFactoryPositionList', usableList)
    },
    /**
     * 查询容器类型详情
     */
    async getDetail(params) {
      const { data, code } = await this.$httpService(
        this.$apiStore.base('queryContainerArchivesById'),
        params
      )
      if (code) return
      const { containerArchivesDetailList = [], containerArchivesList = [] } =
        data || {}
      if (!containerArchivesList?.length) return
      const formModel = containerArchivesList[0]
      formModel.areaId = formModel.areaId
        ? formModel.areaId.split(',').map((t) => parseInt(t))
        : []
      formModel.productionLineId = formModel.productionLineId
        ? formModel.productionLineId.split(',').map((t) => parseInt(t))
        : []
      this.formModel = formModel
      this.containerArchivesDetailList = containerArchivesDetailList || []
      if (
        this.containerTypePatternType === 'moveContainer' &&
        this.containerArchivesDetailList?.length
      ) {
        containerArchivesList[0].factoryPositionId =
          this.containerArchivesDetailList[0].factoryPositionId
      }
      this.containerArchivesList = containerArchivesList
      this.formModel.containerTypePattern =
        this.containerTypeList?.find(
          (t) => t.value === this.formModel.containerTypeCode
        )?.label ?? ''
    },
    /**
     * 容器类型切换
     */
    async handleContainerType(val, formItem, changeFormValue, formModel) {
      this.$set(this.formModel, formItem.name, val)
      // 固定容器自动生成编码
      const containerTypePatternType = this.containerTypeList?.find(
        (t) => t.value === val
      )?.containerTypePatternType
      this.formModel.containerTypePatternType = containerTypePatternType
      if (containerTypePatternType === 'fixedContainer') {
        const containerArchivesCode = await this.getSerialBizCode(
          'containerArchivesSerialNum',
          'containerArchivesCode'
        )
        this.formModel.containerArchivesCode = containerArchivesCode
        this.containerArchivesList = [{ ...this.formModel }]
      } else if (containerTypePatternType === 'moveContainer') {
        this.getNoUseFactoryPositionList({
          warehouseAreaId: this.formModel.warehouseAreaId,
          containerArchivesId: this.formModel.id,
          usableFlag: 0
        })
        this.containerArchivesList = []
      } else {
        this.containerArchivesList = []
      }
      // 根据容器类型id获取容器类型详情接口
      this.getContainerTypeDetail({ id: val })
    },
    /**
     * 查询容器类型详情
     */
    async getContainerTypeDetail(params) {
      const { data, code } = await this.$httpService(
        this.$apiStore.base('queryContainerTypeDetail'),
        params
      )
      if (code) return
      const { containerType = [], containerTypeFloorList = [] } = data || {}
      this.$set(
        this.formModel,
        'containerTypePattern',
        containerType?.containerTypePattern ?? ''
      )
      this.initContainerPositionTable(containerTypeFloorList)
    },
    /**
     * 初始化容器位置信息
     */
    initContainerPositionTable(list = []) {
      const maxColumn = this.getMaxColumn(list)
      const tableData = []
      for (let i = 1; i <= maxColumn; i++) {
        tableData.push({
          containerArchivesColumn: i,
          factoryPositionId: null,
          containerArchivesCode: this.formModel.containerArchivesCode
        })
      }
      this.containerArchivesDetailList = tableData
    },
    /**
     * 获取容器类型所有层中最大的列数
     */
    getMaxColumn(list = []) {
      list?.sort((a, b) => {
        return b.columnNum - a.columnNum
      })
      return list?.length ? list[0].columnNum ?? 0 : 0
    },
    /**
     * 所属区域 change
     * 区域-产线
     */
    handleAreaChange(val, formItem, changeFormValue, formModel) {
      this.$set(this.formModel, formItem.name, val)
      if (val) {
        this.getProductionLineList({ areaIdList: this.formModel.areaId })
      } else {
        this.productionLineList = []
      }
      this.formModel.productionLineId = []
    },
    /**
     * 所属库区 change
     * 库区-库位
     */
    handleWarehouseArea(val, formItem, changeFormValue, formModel) {
      this.noUsefactoryPositionList = []
      this.$set(this.formModel, formItem.name, val)
      // 清空容器位置库位
      this.containerArchivesDetailList.forEach((item) => {
        if (item.factoryPositionId) {
          item.factoryPositionId = null
        }
      })
      // 清空容器位置库位
      this.containerArchivesList.forEach((item) => {
        if (item.factoryPositionId) {
          item.factoryPositionId = null
        }
      })
      this.getNoUseFactoryPositionList({
        warehouseAreaId: val,
        containerArchivesId: this.formModel.id,
        usableFlag: 0
      })
    },
    /**
     * 添加信息
     */
    handleInputBatchNum(val) {
      const addNum = val
      this.$set(this.formModel, 'addNum', addNum)
    },
    /**
     * 批量新增
     */
    async handleBatchAdd(formModel) {
      if (!this.formModel.addNum) {
        this.$message.error('请输入新增数量')
        return
      }
      this.$refs.myForm.getValidateFormModel().then(async (model) => {
        this.vloading = true
        const {
          data: { startNum = 1, endNum = 1 },
          code
        } = await this.$httpService(this.$apiStore.base('getOrderNumber'), {
          numberLength: formModel.addNum,
          bitType: 'containerArchivesOrderNumberCode'
        })
        this.vloading = false
        if (code) return
        const list = []
        const { codedLetterPrefix } = formModel
        for (let i = startNum; i < endNum; i++) {
          const serialNum = this.gegeSerialnumber(i)
          list.push({
            containerArchivesCode: `${codedLetterPrefix}${serialNum}`,
            hostCode: null,
            usableFlag: 0
          })
        }
        this.containerArchivesList = list
      })
    },
    /**
     * 前端根据后端返回自增序号  生成编码
     */
    gegeSerialnumber(i) {
      let numStr = i + ''
      let diff = 8 - numStr.length
      while (diff) {
        numStr = `0${numStr}`
        diff--
      }
      return numStr
    },
    async updateUsableFlag(val, model) {
      if (this.mode === 'edit') {
        let confirmMsg = ''
        const stockList = await this.getStockList({
          containerArchivesIds: [this.formModel.id]
        })
        if (stockList?.length && val === 0) {
          confirmMsg = this.$t('lang.gles.container.checkMessage5')
        }
        if (confirmMsg) {
          this.$message.error(confirmMsg)
          return
        }
      }
      model.usableFlag = val ? 0 : 1
      // this.$set(model, 'usableFlag', val ? 0 : 1)
    },
    /**
     * 获取容器占用库存列表
     * @param {*} params
     */
    async getStockList(params) {
      const { data, code } = await this.$httpService(
        this.$apiStore.inventoryList('queryStockListByCondition'),
        params
      )
      if (code) return
      return data || []
    },
    /**
     * 表单提交
     */
    onSubmit() {
      // 先校验
      this.$refs.myForm.getValidateFormModel().then((model) => {
        if (this.isShowContainerPosition) {
          this.$refs.positionTableForm.validateTableForm().then((data) => {
            this.submitForm(this.formModel)
          })
        } else {
          this.$refs.containerTableForm.validateTableForm().then((data) => {
            this.submitForm(this.formModel)
          })
        }
      })
    },
    /**
     * 表单校验通过，提交表单
     * @param {*} model
     */
    async submitForm(model = {}) {
      const formModel = { ...this.rowDetail, ...model }
      const params = {}
      // 组合参数
      let { containerArchivesList = [] } = this
      if (!containerArchivesList?.length) {
        this.$message.error(this.$t('lang.gles.container.checkMessage6'))
        return
      }
      containerArchivesList = containerArchivesList.map((item) => {
        const tempItem = {
          ...item,
          id: this.formModel.id ?? null,
          containerTypeId: this.formModel.containerTypeId,
          areaId: this.formModel?.areaId?.join(',') ?? '',
          productionLineId: this.formModel?.productionLineId?.join(',') ?? '',
          warehouseAreaId: this.formModel.warehouseAreaId
        }
        if (this.isShowContainerPosition) {
          return {
            ...tempItem,
            hostCode: this.formModel.hostCode,
            containerArchivesCode: this.formModel.containerArchivesCode,
            usableFlag: this.formModel.usableFlag
          }
        }
        return tempItem
      })
      params.containerArchivesList = containerArchivesList
      if (this.containerTypePatternType === 'fixedContainer') {
        params.containerArchivesDetailList =
          this.containerArchivesDetailList.map((item) => {
            const tempItem = {
              ...item,
              containerArchivesCode: formModel.containerArchivesCode
            }
            return tempItem
          })
      } else if (this.containerTypePatternType === 'moveContainer') {
        const list = containerArchivesList.filter((t) => t.factoryPositionId)
        params.containerArchivesDetailList = list.map((item, i) => {
          return {
            ...this.containerArchivesDetailList[i],
            containerArchivesCode: item.containerArchivesCode,
            factoryPositionId: item.factoryPositionId
          }
        })
      }
      this.mode === 'add'
        ? await this.insert(params)
        : await this.update(params)
    },
    /**
     * 新增容器档案
     * @param {*} params
     */
    async insert(params) {
      const { code } = await this.$httpService(
        this.$apiStore.base('insertContainerArchives'),
        params
      )
      if (code) return
      this.$message.success(this.$t('lang.gles.common.addSuccessfully'))
      this.onCancel()
    },
    /**
     * 更新容器档案
     * @param {*} params
     */
    async update(params) {
      const { code } = await this.$httpService(
        this.$apiStore.base('updateContainerArchives'),
        params
      )
      if (code) return
      this.$message.success(this.$t('lang.gles.common.updateSuccessfully'))
      this.onCancel()
    },
    /**
     * 取消
     */
    onCancel() {
      this.$emit('updateCom', {
        currentCom: 'ContainerFileList'
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.btns {
  z-index: 10;
  height: 60px;
  line-height: 60px;
  margin-top: 10px;
  background: #fff;
  text-align: center;
}
</style>

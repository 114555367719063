<template>
  <div class="container-type">
    <component
      :is="currentCom"
      :mode="mode"
      :row-detail.sync="row"
      @updateCom="updateCom"
    />
  </div>
</template>
<script>
import ContainerFileList from './components/list.vue'
import ContainerFileAdd from './components/add.vue'
import ContainerFileDetail from './components/detail.vue'
export default {
  components: {
    ContainerFileList,
    ContainerFileAdd,
    ContainerFileDetail
  },
  data() {
    return {
      currentCom: 'ContainerFileList',
      mode: '',
      row: {}
    }
  },
  created() {
    this.$EventBus.$on('page:updateCom', this.updateCom)
  },
  methods: {
    updateCom({ currentCom, mode, row }) {
      this.currentCom = currentCom
      this.mode = mode
      this.row = row
    }
  }
}
</script>
<style lang="scss" scoped></style>

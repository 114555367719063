<template>
  <div class="basic-data-list">
    <query-view
      ref="myQueryView"
      name="codeOrType"
      :value.sync="baseFormModel.codeOrType"
      :placeholder="$t('lang.gles.common.pleaseInputContainerCode')"
      :query-list="moreQueryData"
      @baseSearch="handleBaseSearch"
      @moreSearch="handleMoreSearch"
    />
    <el-card class="table-card">
      <div class="operator-wrapper">
        <el-button type="primary" icon="el-icon-plus" @click="updateCom">
          {{ $t('lang.gles.common.addNew') }}
        </el-button>
        <el-button
          type="danger"
          icon="el-icon-delete"
          :disabled="!multipleSelectionList.length"
          @click="handleBatchDelete"
        >
          {{ $t('lang.gles.common.batchDelete') }}
        </el-button>
        <!-- 列设置 -->
        <column-config :list.sync="columnConfigList" />
      </div>
      <m-table
        :table-item="tableItem"
        :table-data="tableData"
        :page-data="pageData"
        :extend-config="tableExtendConfig"
        @editItem="editItem"
        @delItem="delItem"
        @pageChange="pageChange"
        @selection-change="handleSelectionChange"
      >
        <template #usableFlag="{ row }">
          <el-switch
            :value="row.usableFlag"
            class="switch"
            :active-text="$t('lang.gles.common.enable')"
            :inactive-text="$t('lang.gles.common.disable')"
            :active-value="0"
            :inactive-value="1"
            :disabled="row.containerStatus !== 0"
            @click.native="handleChangeUsableStatus(row)"
          />
        </template>
      </m-table>
    </el-card>
    <!-- 详情 -->
    <detail-dialog
      v-if="detailDialogVisible"
      :visible.sync="detailDialogVisible"
      :row-detail="row"
      :mode="mode"
    />
  </div>
</template>
<script>
import ColumnConfig from '@/components/columnConfig'
import QueryView from '@/components/moreQuery/queryView.vue'
import MTable from '@/libs_sz/components/MTable/MTable'
import { getMoreQueryFormData, getSearchTableItem } from '../data'
import * as types from '@/store/mutationTypes'
import listMixins from '@/mixins/listMixins'
import commonMixins from '@/mixins/commonMixins'
import { mapState } from 'vuex'
import DetailDialog from './detail.vue'

export default {
  name: 'ContainerFileList',
  components: {
    ColumnConfig,
    QueryView,
    MTable,
    DetailDialog
  },
  mixins: [listMixins, commonMixins],
  data() {
    return {
      tableData: [],
      formModel: {},
      baseFormModel: {
        codeOrType: ''
      },
      multipleSelectionList: [],
      containerTypeList: [],
      detailDialogVisible: false,
      mode: ''
    }
  },
  computed: {
    ...mapState('base', [
      'containerTypePatternTypeList',
      'containerStatusList',
      'statusFlagList',
      'containerTypePatternList'
    ]),
    moreQueryData() {
      return getMoreQueryFormData(this)
    },
    /**
     * 默认的表格列表项
     */
    defaultTableItem() {
      return getSearchTableItem(this)
    },
    /**
     * 渲染的表格列表项  列设置
     */
    tableItem() {
      let list = this.defaultTableItem
      if (this?.columnConfigList?.length) {
        list = this.defaultTableItem.filter(
          (item, i) =>
            item.name === this.columnConfigList[i]?.name &&
            this.columnConfigList[i]?.isShow
        )
      }
      return list
    }
  },
  async created() {
    this.tableExtendConfig = {
      ...this.tableExtendConfig,
      checkBox: true
    }
    await this.initSelectList()
  },
  mounted() {
    this.doSearch(this.baseFormModel)
  },
  methods: {
    initSelectList() {
      this.getContainerTypeList()
      this.getAreaList()
      this.getProductionLineList()
      this.getWarehouseAreaList()
      this.getFactoryPositionList()
      if (!this.containerStatusList?.length) {
        this.$store.dispatch('base/getContainerStatusList')
      }
      if (!this.statusFlagList?.length) {
        this.$store.dispatch('base/getStatusFlagList')
      }
      if (!this.containerTypePatternList?.length) {
        this.$store.dispatch('base/getContainerTypePatternList')
      }
    },
    // 查询
    async doSearch(params, api = 'getContainerArchivesListByCodeOrType') {
      try {
        const { data, code } = await this.$httpService(
          this.$apiStore.base(api, this.pageData),
          params
        )
        if (code) return
        this.$store.commit(types.IS_LOADING, false)
        const {
          recordList = [],
          currentPage,
          pageSize,
          totalRecordCount: recordCount
        } = data || {}
        this.pageData = { currentPage, pageSize, recordCount }
        const list = recordList?.map((item) => {
          return {
            ...item,
            isSelection: !!item.usableFlag
          }
        })
        this.tableData = list || []
      } catch (error) {
        this.$store.commit(types.IS_LOADING, false)
      }
    },
    /**
     * 查看详情
     */
    handleDetail(row) {
      this.detailDialogVisible = true
      this.row = row
      this.mode = 'detail'
    },
    // 新增
    updateCom() {
      this.$emit('updateCom', {
        currentCom: 'ContainerFileAdd',
        mode: 'add'
      })
    },
    /**
     * 所属区域 change
     * 区域-产线
     */
    handleAreaChange(val, formItem, changeFormValue, formModel) {
      this.$set(this.formModel, formItem.name, val)
      if (this.formModel.productionLineId) {
        this.formModel.productionLineId = null
      }
      this.getProductionLineList({ areaId: val })
    },
    /**
     * 所属区域 change
     * 库区-库位
     */
    handleWarehouseArea(val, formItem, changeFormValue, formModel) {
      this.$set(this.formModel, formItem.name, val)
      this.getFactoryPositionList({ warehouseAreaId: val })
    },
    handleSelectionChange(val) {
      this.multipleSelectionList = val
    },
    /**
     * 获取容器占用库存列表
     * @param {*} params
     */
    async getStockList(params) {
      const { data, code } = await this.$httpService(
        this.$apiStore.inventoryList('queryStockListByCondition'),
        params
      )
      if (code) return
      return data || []
    },
    /**
     * 启用/禁用状态切换
     * 1、状态为占用的容器，点击禁用，弹窗提示，若确定强制禁用，则禁用
     * 2、启用容器 容器正常，但有库存记录， 点击禁用，弹窗提示，若强制禁用，则禁用
     */
    async handleChangeUsableStatus(row) {
      let confirmMsg = ''
      if (row.containerStatus !== 0 && row.usableFlag === 0) {
        confirmMsg = this.$t('lang.gles.container.checkMessage4')
      }
      const stockList = await this.getStockList({
        containerArchivesIds: [row.id]
      })
      if (stockList?.length && row.usableFlag === 0) {
        confirmMsg = this.$t('lang.gles.container.checkMessage5')
      }
      if (confirmMsg) {
        this.$message.error(confirmMsg)
        return
      }
      this.updateUsableStatus(row)
    },
    /**
     * 更新启用/禁用状态
     */
    async updateUsableStatus(row) {
      const params = {
        containerArchivesList: [
          { id: row.id, usableFlag: row.usableFlag ? 0 : 1 }
        ]
      }
      const { code } = await this.$httpService(
        this.$apiStore.base('updateContainerArchives'),
        params
      )
      if (code) return
      row.usableFlag = row.usableFlag ? 0 : 1
      row.isSelection = !!row.usableFlag
    },
    /**
     * 基础查询
     */
    handleBaseSearch(data) {
      this.doSearch({ ...data })
    },
    /**
     * 更多查询
     */
    handleMoreSearch(data) {
      this.formModel = { ...data }
      this.doSearch({ ...data }, 'getContainerArchivesList')
    },
    /**
     * 编辑
     */
    editItem({ row }) {
      if (row.containerStatus !== 0) {
        this.$message.error(this.$t('lang.gles.container.checkMessage2'))
        return
      }
      this.$emit('updateCom', {
        currentCom: 'ContainerFileAdd',
        mode: 'edit',
        row
      })
    },
    /**
     * 批量删除
     * 二次确认
     */
    async handleBatchDelete() {
      const confirmMsg = this.$t('lang.gles.common.deleteConfirmMsg0')
      this.$confirm(confirmMsg, '', {
        confirmButtonText: this.$t('lang.gles.common.confirm'),
        cancelButtonText: this.$t('lang.gles.common.cancel'),
        type: 'warning'
      }).then(() => {
        this.batchDeleteContainerType()
      })
    },
    /**
     * 批量删除
     */
    async batchDeleteContainerType() {
      const delIds = this.multipleSelectionList.map((item) => item.id)
      const { code } = await this.$httpService(
        this.$apiStore.base('deleteContainerArchivesByIdBatch'),
        { ids: delIds }
      )
      if (code) return
      this.$message.success(this.$t('lang.gles.common.deleteSuccessfully'))
      if (delIds?.length === this.tableData?.length) {
        this.pageData.currentPage = 1
      }
      this.doSearch(this.baseFormModel)
    },
    /**
     * 删除容器档案
     * 1、启用状态不可删除
     * 2、容器存在库存记录 不可删除
     * 3、删除成功，需同步GMS离场删除 若GMS不存在，则直接删除； 若GMS删除失败，提示【删除失败，GMS执行层容器工作中，请确认】
     */
    async delItem({ row }) {
      // 启用状态
      if (row.usableFlag === 0) {
        this.$message.error(this.$t('lang.gles.container.checkMessage3'))
        return
      }
      const stockList = this.getStockList({ containerArchivesIds: [row.id] })
      if (stockList?.length) {
        this.$message.error(this.$t('lang.gles.container.checkMessage1'))
        return
      }
      const { code } = await this.$httpService(
        this.$apiStore.base('deleteContainerArchivesById'),
        { id: row.id }
      )
      if (code) return
      this.$message.success(this.$t('lang.gles.common.deleteSuccessfully'))
      if (this.tableData?.length < 2) {
        this.pageData.currentPage = 1
      }
      this.doSearch(this.baseFormModel)
    },
    /**
     * 更多查询重置方法
     * @param {*} data
     */
    handleReset(data) {}
  }
}
</script>

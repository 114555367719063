<template>
  <el-drawer :visible="visible" size="80%" custom-class="gles-drawer" :with-header="false" @update:visible="$emit('update:visible', $event)">
    <div class="drawer-wrapper">
      <div class="drawer-operate-btn">
        <el-button type="primary" @click="handleEdit">
          {{ $t('lang.gles.common.edit') }}
        </el-button>
        <el-button type="danger" @click="handleDelete">
          {{ $t('lang.gles.common.delete') }}
        </el-button>
        <i class="el-icon-close" @click="$emit('update:visible', false)" />
      </div>
      <section class="drawer-content">
        <m-form ref="myForm" :form-data="baseFormData" label-position="right" :label-width="120" :extend-config="extendFormConfig">
          <template #appendComponent="{ item }">
            <el-input v-model.number="formModel[item.name]" v-bind="item" class="gles">
              <el-button slot="append" type="primary" @click="() => handleBatchAdd(formModel)">
                {{ $t('lang.gles.common.batchAdd') }}
              </el-button>
            </el-input>
          </template>
        </m-form>
        <div v-if="isShowContainerPosition" class="fixed-shelf">
          <h3 class="title">
            {{ $t('lang.gles.base.containerPosition') }}
          </h3>
          <table-form :table-item="archivesDetailTableItem" :table-data.sync="containerArchivesDetailList" :extend-config="tableExtendConfig" />
        </div>
        <div v-if="!isShowContainerPosition && formModel.containerTypeCode" class="fixed-shelf">
          <table-form :table-item="archivesTableItem" :table-data.sync="containerArchivesList" :extend-config="tableExtendConfig" />
        </div>
      </section>
    </div>
  </el-drawer>
</template>
<script>
import TableForm from '@/components/tableForm/index.vue'
import MForm from '@/libs_sz/components/MForm/MForm'
import addMixins from '@/mixins/addMixins'
import commonMixins from '@/mixins/commonMixins'
import { getEditBaseFormData, getArchivesTableItem, getArchivesDetailTableItem } from '../data'
import { mapState } from 'vuex'

export default {
  name: 'ContainerFileDetail',
  components: {
    TableForm,
    MForm
  },
  mixins: [addMixins, commonMixins],
  props: {
    mode: {
      type: String,
      default: 'detail'
    },
    rowDetail: {
      type: Object,
      default: () => ({})
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // form表单相关的
      extendFormConfig: {
        isNeedBtn: false
      },
      tableExtendConfig: {
        sortNum: true,
        maxHeight: '350px',
        border: true
      },
      formModel: {
        codedLetterPrefix: 'CN'
      },
      tableFormData: [],
      containerArchivesDetailList: [],
      containerArchivesList: [],
      containerTypeList: []
    }
  },
  computed: {
    ...mapState('base', ['containerTypePatternTypeList']),
    baseFormData() {
      const { containerArchivesList = [] } = this.rowDetail
      const row = containerArchivesList?.length ? containerArchivesList[0] : {}
      return getEditBaseFormData(this, {
        ...row,
        isShowCodeRule: this.isShowCodeRule
      })
    },
    archivesTableItem() {
      return getArchivesTableItem(this)
    },
    archivesDetailTableItem() {
      return getArchivesDetailTableItem(this)
    },
    /**
     * 容器形态类型
     */
    containerTypePatternType() {
      return this.containerTypeList?.find((t) => t.value === this.formModel.containerTypeId)?.containerTypePatternType ?? null
    },
    /**
     * 是否显示容器位置信息
     */
    isShowContainerPosition() {
      return this.containerTypePatternType === 'fixedContainer'
    },
    /**
     * 是否显示编码生成规则
     */
    isShowCodeRule() {
      return this.mode === 'add' && ['moveContainer', 'turnoverContainer'].includes(this.containerTypePatternType)
    }
  },
  created() {
    this.initSelectList()
    this.getDetail({ id: this.rowDetail.id })
  },
  methods: {
    initSelectList() {
      this.getAreaList()
      this.getContainerTypeList()
      this.getProductionLineList()
      this.getWarehouseAreaList()
      this.getFactoryPositionList()
    },
    /**
     * 查询容器类型详情
     */
    async getDetail(params) {
      const { data, code } = await this.$httpService(this.$apiStore.base('queryContainerArchivesById'), params)
      if (code) return
      const { containerArchivesDetailList = [], containerArchivesList = [] } = data || {}
      if (!containerArchivesList?.length) return
      this.containerArchivesDetailList = containerArchivesDetailList
      this.containerArchivesList = containerArchivesList
      this.formModel = containerArchivesList[0]
      this.formModel.containerTypePattern = this.containerTypeList?.find((t) => t.value === this.formModel.containerTypeCode)?.label ?? ''
    },
    /**
     * 获取容器占用库存列表
     * @param {*} params
     */
    async getStockList(params) {
      const { data, code } = await this.$httpService(this.$apiStore.inventoryList('queryStockListByCondition'), params)
      if (code) return
      return data || []
    },
    /**
     * 编辑
     */
    async handleEdit() {
      if (this.rowDetail?.containerStatus !== 0) {
        this.$message.error(this.$t('lang.gles.container.checkMessage2'))
        return
      }
      this.$EventBus.$emit('page:updateCom', {
        currentCom: 'ContainerTypeAdd',
        mode: 'edit',
        row: this.rowDetail
      })
    },
    /**
     * 删除
     */
    handleDelete() {
      // 二次确认
      this.$confirm(this.$t('lang.gles.common.deleteConfirmMsg0'), '', {
        confirmButtonText: this.$t('lang.gles.common.confirm'),
        cancelButtonText: this.$t('lang.gles.common.cancel'),
        type: 'warning',
        confirm: () => {
          this.confirmDelete()
        },
        cancel: () => {}
      })
    },
    async confirmDelete() {
      if (this.rowDetail.containerStatus !== 0) {
        this.$message.error(this.$t('lang.gles.container.checkMessage0'))
        return
      }
      const stockList = this.getStockList({ containerArchivesIds: [this.rowDetail.id] })
      if (stockList?.length) {
        this.$message.error(this.$t('lang.gles.container.checkMessage1'))
        return
      }
      const { code } = await this.$httpService(this.$apiStore.base('deleteContainerArchivesById'), {
        id: this.rowDetail.id
      })
      if (code) return
      this.$message.success(this.$t('lang.gles.common.deleteSuccessfully'))
      this.$emit('update:visible', false)
      this.$parent.doSearch(this.formModel)
    }
  }
}
</script>
<style lang="scss" scoped>
.el-table__header-wrapper {
  overflow: auto;
}
/deep/ .el-drawer__header {
  margin-bottom: -30px;
}
/deep/ .el-drawer__body {
  overflow-y: scroll;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"basic-data-add"},[_c('header-path',{attrs:{"paths":_vm.headerPaths}}),_c('el-card',{staticClass:"add-card"},[_c('m-form',{ref:"myForm",attrs:{"form-data":_vm.baseFormData,"label-position":"right","label-width":120,"extend-config":_vm.extendFormConfig},scopedSlots:_vm._u([(_vm.mode === 'add')?{key:"appendComponent",fn:function(ref){
var item = ref.item;
return [_c('el-input',_vm._b({staticClass:"gles",attrs:{"value":item.value},on:{"input":_vm.handleInputBatchNum}},'el-input',item,false),[_c('el-button',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.vloading),expression:"vloading"}],attrs:{"slot":"append","type":"primary","disabled":_vm.vloading || _vm.containerTypePatternType === 'fixedContainer'},on:{"click":function () { return _vm.handleBatchAdd(_vm.formModel); }},slot:"append"},[_vm._v(" "+_vm._s(_vm.$t('lang.gles.common.batchAdd'))+" ")])],1)]}}:null,{key:"usableFlagSlot",fn:function(ref){
var item = ref.item;
return [_c('el-switch',{staticClass:"switch",attrs:{"value":item.value,"active-text":_vm.$t('lang.gles.common.enable'),"inactive-text":_vm.$t('lang.gles.common.disable'),"active-value":0,"inactive-value":1},nativeOn:{"click":function($event){return _vm.updateUsableFlag(item.value, _vm.formModel)}}})]}}],null,true)}),(_vm.isShowContainerPosition)?_c('div',{staticClass:"fixed-shelf",staticStyle:{"margin-left":"50px"}},[_c('h3',{staticClass:"title",staticStyle:{"margin-bottom":"10px"}},[_vm._v(" "+_vm._s(_vm.$t('lang.gles.base.containerPosition'))+" ")]),_c('table-form',{ref:"positionTableForm",attrs:{"table-item":_vm.archivesDetailTableItem,"table-data":_vm.containerArchivesDetailList,"extend-config":_vm.tableExtendConfig},on:{"update:tableData":function($event){_vm.containerArchivesDetailList=$event},"update:table-data":function($event){_vm.containerArchivesDetailList=$event}}})],1):_vm._e(),(!_vm.isShowContainerPosition && _vm.formModel.containerTypeId)?_c('div',{staticClass:"fixed-shelf",staticStyle:{"margin-left":"50px"}},[_c('table-form',{ref:"containerTableForm",attrs:{"table-item":_vm.archivesTableItem,"table-data":_vm.containerArchivesList,"extend-config":_vm.tableExtendConfig},on:{"update:tableData":function($event){_vm.containerArchivesList=$event},"update:table-data":function($event){_vm.containerArchivesList=$event}},scopedSlots:_vm._u([{key:"usableFlag",fn:function(ref){
var row = ref.row;
var $index = ref.$index;
return [_c('el-switch',{staticClass:"switch",attrs:{"value":row.usableFlag,"active-text":_vm.$t('lang.gles.common.enable'),"inactive-text":_vm.$t('lang.gles.common.disable'),"active-value":0,"inactive-value":1},nativeOn:{"click":function($event){return _vm.updateUsableFlag(row.usableFlag, _vm.containerArchivesList[$index])}}})]}}],null,false,1070490494)})],1):_vm._e(),_c('div',{staticClass:"btns"},[_c('el-button',{on:{"click":_vm.onCancel}},[_vm._v(" "+_vm._s(_vm.$t('lang.gles.common.cancel'))+" ")]),_c('el-button',{directives:[{name:"debounce",rawName:"v-debounce",value:(_vm.onSubmit),expression:"onSubmit"}],attrs:{"type":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('lang.gles.common.save'))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }